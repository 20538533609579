import React, { FC } from "react"

import { Container, Content, BoxTitle, BoxIcons, BoxIconMedia, BoxIconMobile, ButtonClose, CardInfo, BoxInfo, BoxValue, BoxButton, BoxQrcode } from './style'
import IconWhatsapp from "../../assets/icons/_IconWhatsapp"
import IconWaze from "../../assets/icons/_IconWaze"
import IconInter from "../../assets/icons/_IconInter"
import IconMap from "../../assets/icons/_IconMap"
import IconInstagram from "../../assets/icons/_IconInstagram"
import IconTiktok from "../../assets/icons/_IconTiktok"
import IconPinterest from "../../assets/icons/_IconPinterest"
import IconTelegram from "../../assets/icons/_IconTelegram"
import IconTwitter from "../../assets/icons/_IconTwitter"
import IconTinder from "../../assets/icons/_IconTinder"
import IconFacebook from "../../assets/icons/_IconFacebook"
import IconClose from "../../assets/icons/_IconClose"
import IconCheck from "../../assets/icons/_IconCheck"
import Qrcode from "../../assets/_Qrcode"
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"
import { ICardInterCelBirthday } from "../CardInterCelBirthday/_index"

type ModalCardInterCelProps = {
  data: ICardInterCelBirthday;
  onClose: () => void;
}

const ModalCardInterCel: FC<ModalCardInterCelProps> = (
  { data, onClose }:
  { data: ICardInterCelBirthday; onClose: () => void },
) => {
  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container onClick={handleContainerClick}>
      <Content>
        <ButtonClose>
          <IconClose onClick={onClose} />
        </ButtonClose>
        <BoxTitle>
          <h3>{data?.title}<span>GB</span></h3>
          <p>{data?.descriptionFull}</p>
        </BoxTitle>
        <BoxIcons>
          <BoxIconMobile>
            <p>Apps ilimitados:</p>
            <div>
              <IconInter />
              <IconWhatsapp />
              <IconWaze />
              <IconMap />
            </div>
          </BoxIconMobile>
          {data?.isSocialMedia && (
            <BoxIconMedia>
              <p>Redes Sociais do pacote:</p>
              <div>
                <IconInstagram />
                <IconTiktok />
                <IconFacebook />
                <IconTinder />
                <IconTwitter />
                <IconTelegram />
                <IconPinterest />
              </div>
            </BoxIconMedia>
          )}
        </BoxIcons>
        <hr />
        <BoxInfo>
          <CardInfo>
            <IconCheck />
            <div>
              <p className='info-title'>Ligações ilimitadas</p>
              <p className='info-desc'>Fixo, celular e DDDs usando 015 + DDD + número</p>
            </div>
          </CardInfo>
          <CardInfo>
            <IconCheck />
            <div>
              <p className='info-title'>{data?.validModal}</p>
              <p className='info-desc'>
                Ative a renovação automática e <span>receba mais cashback</span>
              </p>
            </div>
          </CardInfo>
        </BoxInfo>
        <BoxValue>
          <p>{data?.value_promotion}</p>
        </BoxValue>
        <BoxButton>
          <a
            title='Plano de celular do Inter'
            target='_blank'
            rel='noreferrer'
            href='https://inter-co.onelink.me/Qyu7/0dbvmdeu'
            className='btn btn-orange--extra buttonMD'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_04',
                section_name: 'Modal de aplicativos',
                element_action: 'click button',
                element_name: 'Contratar pelo app',
                redirect_url: 'https://inter-co.onelink.me/Qyu7/0dbvmdeu',
              })
            }}
          >
            Contratar pelo app
          </a>
        </BoxButton>
        <BoxQrcode>
          <Qrcode />
          <hr />
          <p>Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos.</p>
        </BoxQrcode>
      </Content>
    </Container>
  )
}

export default ModalCardInterCel
