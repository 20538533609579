import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  gap: 12px;

  padding: 22px 18px 16px 18px;
  border-radius: 20px;
  background-color: #FFFFFF;
`

export const BoxTitle = styled.div`
  h3 {
    font-weight: 600;
    font-family: 'Inter', Helvetica, sans-serif;
    color: #161616;
    font-size: 38px;
    margin: 0;

    span {
      font-size: 16px;
    }
  }

  p {
    font-size: 14px;
    line-height: 16.94px;
    color: #6A6C72;
  }

  @media (min-width: ${breakpoints.lg}){
    h3{
      font-size: 48px;
      line-height: 58px;
      span{
        font-size: 20px;
        line-height: 24px;
      }
    }
    p{
      font-size: 18px;
      line-height: 22px;
    }
  }
`

export const BoxIcons = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;

  @media (min-width: ${breakpoints.md}){
    min-height: 70px;
  }
`

export const BoxIconMobile = styled.div`
  display: flex;
  flex-direction: row;
  
  gap: 6px;
`

export const BoxIconMedia = styled.div`
  display: flex;
  flex-direction: row;

  gap: 6px;
  @media (min-width: ${breakpoints.md}){
    gap: 2px;
  }

`

export const BoxInfo = styled.div`
  margin-top: 12px;

  .value {
    color: #6A6C72;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 400;
    text-decoration: line-through;
    margin-bottom: 8px;
  }

  .value-promotion {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    color: #161616;
  }

  .valid {
    color: #6A6C72;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  @media (min-width: ${breakpoints.lg}){
    .value {
      font-size: 14px;
      line-height: 16.94px;
    }

    .value-promotion {
      font-size: 24px;
      line-height: 29.05px;
    }
  }
`

export const BoxButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  a {
    height: 38px;
    text-transform: none;
  }

  @media (min-width: ${breakpoints.md}){
    .contratarApp{
      display:none;
    }
    .maisDetalhes{
      background-color: #FF7A00;
      color: #FFFFFF;
    }
  }
`
