import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.section`
  background-color: #FDF8EE;
  padding: 40px 0;

  @media (min-width: ${breakpoints.lg}){
    padding: 64px 0;
  }

  @media (min-width: ${breakpoints.xxl}){
    padding: 94px 0;
  }
`

export const Content = styled.div`
  max-width: 300px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  gap: 40px;
  @media (min-width: ${breakpoints.md}){
    flex-direction: row-reverse;
    max-width: 678px;
    align-items: center;
    gap: 70px;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 945px;
    gap: 120px;
  }

  @media (min-width: ${breakpoints.xxl}){
    max-width: 1130px;
    gap: 50px;
  }
`

export const BoxImage = styled.div`
  img{
    width: 100%;
  }
  @media (min-width: ${breakpoints.md}){
    flex-basis: 40%;
  }

  @media (min-width: ${breakpoints.xxl}){
    flex-basis: 45%;
  }
`

export const BoxText = styled.div`
  h2{
    font-weight: 700;
    font-family: 'Citrina', Helvetica, sans-serif;
    color: #161616;

    font-size: 24px;
    line-height: 26.4px;
  }
  p{
    font-weight: 400;
    font-family: 'inter', Helvetica, sans-serif;
    color: #6A6C72;

    font-size: 14px;
    line-height: 16.94px;

    margin: 16px 0 24px 0;
  }
  a{
    font-weight: 700;
    font-family: 'inter', Helvetica, sans-serif;
    color: #FFFFFF;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 16.94px;

    text-transform: capitalize;
    height: 48px;
  }

  @media (min-width: ${breakpoints.md}){  
    flex-basis: 60%;

    h2{
      font-size: 28px;
      line-height: 30.8px;
    }
    
    p{
      font-size: 14px;
      line-height: 16.94px;
    }
  }

  @media (min-width: ${breakpoints.lg}){  
    h2{
      font-size: 40px;
      line-height: 44px;
    }
    
    p{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media (min-width: ${breakpoints.xxl}){ 
    flex-basis: 55%;
    h2{
      font-size: 48px;
      line-height: 52.8px;
    }
    
    p{
      font-size: 18px;
      line-height: 21.78px;
    }
  }
`
