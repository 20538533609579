import React, { FC } from "react"

import { BoxIconMedia, BoxIconMobile, BoxTitle, Container, BoxInfo, BoxButton, BoxIcons } from './style'
import IconWhatsapp from "../../assets/icons/_IconWhatsapp"
import IconWaze from "../../assets/icons/_IconWaze"
import IconInter from "../../assets/icons/_IconInter"
import IconMap from "../../assets/icons/_IconMap"
import IconInstagram from "../../assets/icons/_IconInstagram"
import IconTiktok from "../../assets/icons/_IconTiktok"
import IconFacebook from "../../assets/icons/_IconFacebook"
import IconTinder from "../../assets/icons/_IconTinder"
import IconTwitter from "../../assets/icons/_IconTwitter"
import IconTelegram from "../../assets/icons/_IconTelegram"
import IconPinterest from "../../assets/icons/_IconPinterest"

export type ICardInterCelBirthday = {
  title: string;
  description: string;
  descriptionFull: string;
  isSocialMedia: boolean;
  value: string;
  value_promotion: string;
  valid: string;
  validModal: string;
}

type CardInterCelBirthdayProps = {
  data: ICardInterCelBirthday;
  openModal: () => void;
}

const CardInterCelBirthday: FC<CardInterCelBirthdayProps> = (
  { data, openModal }: { data: ICardInterCelBirthday;
  openModal: () => void;}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    openModal()
  }

  return (
    <Container>
      <BoxTitle>
        <h3>{data?.title}<span>GB</span></h3>
        <p>{data?.description}</p>
        <p className='valid'>{data?.valid}</p>
      </BoxTitle>
      <BoxIcons>
        <BoxIconMobile>
          <IconWhatsapp />
          <IconWaze />
          <IconInter />
          <IconMap />
        </BoxIconMobile>
        {data?.isSocialMedia && (
          <BoxIconMedia>
            <IconInstagram />
            <IconTiktok />
            <IconFacebook />
            <IconTinder />
            <IconTwitter />
            <IconTelegram />
            <IconPinterest />
          </BoxIconMedia>
          )}
      </BoxIcons>
      <BoxInfo>
        <p className='value'>De {data?.value}</p>
        <p className='value-promotion'>{data?.value_promotion}</p>
      </BoxInfo>
      <BoxButton>
        <a
          title='Plano de celular do Inter'
          target='_blank'
          rel='noreferrer'
          href='https://inter-co.onelink.me/Qyu7/0dbvmdeu'
          className='btn btn-orange--extra buttonMD contratarApp'
        >
          Contratar pelo app
        </a>
        <a
          href='#'
          className='btn buttonMD btn-link maisDetalhes'
          onClick={handleClick}
        >
          Mais detalhes
        </a>
      </BoxButton>
    </Container>
  )
 }

 export default CardInterCelBirthday
